// Stylesheets
import "./theme.scss";
import "./resources/images/favicon.png";

// Component Javascript
import "./components/progressbar/progressbar.js";
import "./components/product-overview/product-overview.js";
import languageNavigation from "./components/languagenavigation/languagenavigation";
import xfHeader from "./components/experiencefragment/experiencefragment_header";
import form from "./components/form/form";
import formRedirect from "./components/form-options/form_dropdown--redirect.js";
import Diagnostics from "./components/diagnostics/diagnostics.js";
import externalHtml from "./components/external-html/external-html";


const documentReadyFunctions = () => {
  languageNavigation();
  xfHeader();
  form();
  formRedirect();
  externalHtml();
  document.querySelectorAll('.diagnostics').forEach(el => new Diagnostics(el));
};

const runDocumentReadyFunctions = () => {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    documentReadyFunctions();
  } else {
    requestAnimationFrame(runDocumentReadyFunctions);
  }
};

runDocumentReadyFunctions();

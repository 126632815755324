const formRedirect = () => {
    const formContainer = document.getElementById("_js-register");

    if(formContainer) {
        const button = formContainer?.querySelector('button');
        const oneBusinessIdUrl = formContainer.getAttribute('data-api-csrf');
        const userRegistrationUrl = formContainer.getAttribute('data-api-submit');
        const selectOptions = formContainer?.querySelector(".cmp-form-options__field--drop-down");
        let selected;

        // disable submit button
        button.disabled = true;
    
        selectOptions?.addEventListener('change', (event) => {
            button.disabled = false;
            selected = event.target.value;
        });
    
        // on click event go to correct link
        button.addEventListener('click', (e) => {
            e.preventDefault();

            if ( selected === "other") {
                window.location.href = userRegistrationUrl;
            } else {
                window.location.href = oneBusinessIdUrl;
            }
        });
    }
};

export default formRedirect;

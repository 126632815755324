const externalHtml = () => {
    const htmlContainers = document.getElementsByClassName('html');
    if (htmlContainers.length > 0) {

        const thirdPartyLicenseContainer = document.querySelector('.third-party-license');
        const buttons = thirdPartyLicenseContainer?.querySelectorAll('button[data-testid="accordion-toggle"]') || [{}];

        for( let index = 0; index < buttons?.length; index++) {
            const button = buttons.item(index);

            const toggle = (e) => {
                const targetElement = e.target || e.srcElement;
                const parent = targetElement.parentNode;
                const grandparent = targetElement.parentNode.parentNode;
                const arrows = parent.querySelectorAll('.stroke-dark');
                const content = grandparent.querySelector('[data-testid="accordion-body"]');
                
                toggleClass(content);
                arrows.forEach(toggleClass);

                function toggleClass(item){
                    if (item.classList.contains('hidden')) {
                        item.classList.remove('hidden');
                    }else {
                        item.classList.add('hidden');
                    }
                }
              };
              button.addEventListener("click", toggle);
        }
    }
};
  
export default externalHtml;
const equalProductTitleHeights = () => {
  const teaserTitles = document.querySelectorAll('.cmp-teaser__title');

  if (teaserTitles.length > 0) {
    let maxHeight = 0;

    teaserTitles.forEach(title => {
      maxHeight = Math.max(maxHeight, title.offsetHeight);
    });

    teaserTitles.forEach(title => {
      title.style.height = `${maxHeight}px`; // Set height, not min-height
    });
  }
};

const isDesktopView = () => {
  return window.innerWidth >= 900;
};


if (isDesktopView()) {
  equalProductTitleHeights();
}

window.addEventListener('resize', () => {
  // Execute only on desktop after resize
  if (isDesktopView()) {
    equalProductTitleHeights();
  } else {
    // Reset heights on non-desktop views
    const teaserTitles = document.querySelectorAll('.cmp-teaser__title');
    teaserTitles.forEach(title => {
      title.style.height = 'auto'; // Reset to default height
    });
  }
});

class Diagnostics {
    node: Element;
    releaseDate: string;

    constructor(node: Element) {
        this.node = node;

        const metadataSubline = this.node.querySelector('.cmp-diagnostics__item-subline');
        const metadataUrl = metadataSubline?.getAttribute('data-metadata-url');
        const downloadLink = this.node.querySelector('.cmp-diagnostics__download-link');
        const downloadUrl = downloadLink?.getAttribute('data-download-url');
        const apiKeyInput = this.node.querySelector('.cmp-diagnostics__input.api-key');

        downloadLink?.setAttribute('target', '_blank');

        
        function request<TResponse>(
            url: string, 
            config: RequestInit = {}
          ): Promise<TResponse> {
            return fetch(url, config)
              .then(response => response.json())
              .then(data => data)
              .catch(error => {
                // Handle the error.
              })
          }

          type MetaData = {
            path: string;
            releaseDate: string;
            size: string;
          }

          //const metadataUrl2 = 'http://127.0.0.1:8081/latest.json';

          request<MetaData>(metadataUrl || '').then(metaData => {
            this.releaseDate = metaData?.releaseDate;
            const releaseDateWrapper = document.createTextNode(" " + this.releaseDate );
            metadataSubline?.appendChild(releaseDateWrapper);

            apiKeyInput?.addEventListener('input', function(event) {
                var apiKey = event?.target?.value;
                downloadLink?.setAttribute('href', downloadUrl + apiKey);
                downloadLink?.classList.add('is-visible');
            });
          })

    }
}

export default Diagnostics;
  
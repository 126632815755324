const xfHeader = () => {
  const $header = document.getElementsByClassName("cmp-experiencefragment")[0].firstElementChild;
  const $btn = document.createElement("button");
  const $elements = document.querySelectorAll(
    ".navigation, .search, .languagenavigation"
  );
  const $preContainer = document.getElementsByClassName('container_pre_content')[0];

  $btn.id = "mobile-header-button";
  $btn.setAttribute("aria-label", "Toggle Navigation");
  $header?.appendChild($btn);

  $elements.forEach((el) => el.classList.add("_js-hide"));

  const toggle = () => {
    $header?.classList.toggle("_js-open");
    $btn.classList.toggle("_js-openbtn");
    $elements.forEach((el) => el.classList.toggle("_js-hide"));
    document.body.classList.toggle("_js-overflow-hidden");
    document.body.classList.toggle("_js-main-nav--open");
  };

  $btn.addEventListener("click", toggle);

  var className = "_js-inverted";
  var scrollTrigger = 60;

 const heroTeaser = $preContainer.getElementsByClassName('teaser--hero');
   
  // if there is no Hero Teaser 
  if (heroTeaser.length == 0) {
    document.getElementsByClassName("main-header")[0].classList.add(className);
  }
  else {
    window.onscroll = function() {
      // We add pageYOffset for compatibility with IE.
      if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
        document.getElementsByClassName("main-header")[0].classList.add(className);
      } else {
        document.getElementsByClassName("main-header")[0].classList.remove(className);
      }
    };

  }



};

export default xfHeader;
